@import url(https://fonts.googleapis.com/css?family=Bebas+Neue|Oswald|Roboto:300,400,500,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  padding-left: 20px;
  background-color: #5FBFF9;
  min-height: 25px;
  max-height: 5%;
  display: flex;
  flex-direction: row;
  font-family: Oswald;
  font-size: calc(10px + 2vmin);
  color: #FFFFF2;
  letter-spacing: 0.05em;
}

.App-link {
  color: #171D1C;
}

.App-body {
  width: 100%;
  background-color: #5FBFF9;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.selected{
    display: inline-block;
    box-sizing: content-box;
    max-width: 75px;
    max-height: 25px;
    width: auto;
    height: auto;
    position: relative;
    margin: 10px;
    padding: 5px;
    border: 2px solid rgba(0,150,255,0.91);
    -webkit-border-radius: 10px;
    font-family: Oswald, sans-serif;
    color: rgba(255,255,255,1);
    text-align: center;
    text-overflow: ellipsis;
    background: rgba(52,120,229,1);
    box-shadow: 3px 3px 4px 0 rgba(0,0,0,0.4) ;
  }

.notSelected{
  max-width: 125px;
  max-height: 50px;
  width: auto;
  height: auto;
  margin: 10px;
  padding: 5px;
  font-family: Roboto;
  color: rgba(255,255,255,1);
  vertical-align: middle;
  text-align: center;
  text-overflow: ellipsis;
  background: rgba(52,120,229,1);
  box-shadow: 2px 2px 3px 0 rgba(0,0,0,0.4) ;
}
.boardgame {
  box-sizing: content-box;
  max-height: 600px;
  min-height: 300px;
  width: 66.7%;
  margin-bottom: 30px;
  font: normal 14px/1 "Times New Roman", Times, serif;
  color: snow;
  text-overflow: ellipsis;
  background: #FFFFF2;
  box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.4);
  z-index: 5;
}

.image {
  max-width: 50px;
  height: auto;
  margin: 5px;
  position: absolute;
}

.thumbnail {
  max-width: 150px;
  height: auto;
  width: auto;
}

.title-bar {
  height: 75px;
  background-color: #5863F8;
}

.title-text {
  font-family: "Oswald", sans-serif;
  padding-left: 155px;
  padding-top: 5px;
  color: #FFFFF2;
  letter-spacing: 0.05em;
}

.information-container {
  background-color: #FFFFF2;
}

.information-text {
  color: black;
  font-family: "Oswald", sans-serif;
  padding-top: 10px;
  padding-left: 100px;

}

.tags {
  background-color: #FFFFF2;
  padding-left: 10px;
  padding-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}


@media only screen and (max-width: 1079px)
{
  .boardgame {
    box-sizing: content-box;
    max-height: 600px;
    min-height: 300px;
    width: 100%;
    margin: 10px;
    margin-bottom: 30px;
    font: normal 14px/1 "Times New Roman", Times, serif;
    color: snow;
    text-overflow: ellipsis;
    background: #FFFFF2;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.4);
    z-index: 5;
  }

  .thumbnail {
    max-width: 100px;
    height: auto;
    width: auto;
  }
  
  .title-bar {
    height: 75px;
    background-color: #5863F8;
  }
  
  .title-text {
    font-family: "Oswald", sans-serif;
    padding-left: 105px;
    padding-top: 5px;
    color: #FFFFF2;
    letter-spacing: 0.05em;
  }
  
}

@media only screen and (min-width: 1081px) and (max-width: 1599px)
{
  .boardgame {
    box-sizing: content-box;
    max-height: 600px;
    min-height: 300px;
    width: 40%;
    margin: 10px;
    margin-bottom: 30px;
    font: normal 14px/1 "Times New Roman", Times, serif;
    color: snow;
    text-overflow: ellipsis;
    background: #FFFFF2;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.4);
    z-index: 5;
  }
}

@media only screen and (min-width: 1600px)
{
  .boardgame {
    box-sizing: content-box;
    max-height: 600px;
    min-height: 300px;
    width: 29.7%;
    margin: 10px;
    font: normal 14px/1 "Times New Roman", Times, serif;
    color: snow;
    text-overflow: ellipsis;
    background: #FFFFF2;
    box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.4);
    z-index: 5;
  }
}

.empty-container{
    width: 100%;
}
.boardgamelist{
    background-color: #5FBFF9;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2.4rem;
    z-index: 1;
}

.header {
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.normalText {
    font-family: Oswald;
    color: #FFFFF2;
    letter-spacing: 0.05em;
}

@media only screen and (max-width: 1080px)
{
.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

}    
}
.normal-text{
    font-family: Oswald;
    color: #171D1C;
    letter-spacing: 0.05em;
}

.normal-button{
    padding: 5px;
}

.modal{
    width: 100%;
    justify-content: center;
}

.header {
    font-family: Oswald;
    color: #171D1C;
    letter-spacing: 0.05em;
}
