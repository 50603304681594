@import url("https://fonts.googleapis.com/css?family=Bebas+Neue|Oswald|Roboto:300,400,500,700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  padding-left: 20px;
  background-color: #5FBFF9;
  min-height: 25px;
  max-height: 5%;
  display: flex;
  flex-direction: row;
  font-family: Oswald;
  font-size: calc(10px + 2vmin);
  color: #FFFFF2;
  letter-spacing: 0.05em;
}

.App-link {
  color: #171D1C;
}

.App-body {
  width: 100%;
  background-color: #5FBFF9;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
